















































import { Component, Vue } from "vue-property-decorator";
import { companyType, companyInfo } from "../api/login";

@Component({
	name: "login"
})
export default class Register extends Vue {
	private formData = {
		company: "",
		// eslint-disable-next-line @typescript-eslint/camelcase
		company_type: "",
		address: "",
		// eslint-disable-next-line @typescript-eslint/camelcase
		company_phone: "",
		contacts: "",
		phone: ""
	};

	private rules = {
		company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
		// eslint-disable-next-line @typescript-eslint/camelcase
		company_type: [
			{ required: true, message: "请选择公司类型", trigger: "change" }
		],
		address: [{ required: true, message: "请输入公司地址", trigger: "blur" }],
		// eslint-disable-next-line @typescript-eslint/camelcase
		company_phone: [
			{ required: true, message: "请输入公司电话", trigger: "blur" }
		],
		contacts: [{ required: true, message: "请输入联系人", trigger: "blur" }],
		phone: [{ required: true, message: "请输入联系人电话", trigger: "blur" }]
	};

	private companyTypeList = [];

	$refs: { [key: string]: any } = {
		form: HTMLInputElement
	};

	private created() {
		this.getCompanyType();
	}

	// 获取公司分类
	private getCompanyType() {
		companyType().then(res => {
			this.companyTypeList = res.data;
		});
	}

	// 提交完善信息
	private submitForm() {
		this.$refs.form.validate((valid: any) => {
			if (valid) {
				companyInfo(this.formData).then(res => {
					this.$router.push({ path: "/" });
				});
			} else {
				console.log("error submit!!");
				return false;
			}
		});
	}
}
